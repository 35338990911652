$(document).ready(function (){
  const dropdownMenu = (function (){
    function showAndHide(){
      $(document).on('click', '.js-dropdown-target', function (){
        if ($($(this).data('dropdown')).hasClass('dropdown--show')){
          $($(this).data('dropdown')).removeClass('dropdown--show');
        } else {
          $($(this).data('dropdown')).addClass('dropdown--show');
        }
      });
    }

    function hideOnOutside(){
      $(document).on('click', function (e){
        const target = e.target;
        const closestItem = target.closest('.js-dropdown-target');
        if (!$(closestItem).is($('.js-dropdown-target'))){
          $(this).find('.js-dropdown-item').removeClass('dropdown--show');
        }
      });
    }

    function hamburgerMenu(){
      // TODO: fix menu
      const burger = $('.js-show-menu');
      const target = $('.profile-nav__list');

      burger.on('click', function (){
        if ($($(this).data('dropdown')).hasClass('profile-nav__list--hide')){
          $(target).removeClass('profile-nav__list--hide');
        } else {
          $(target).removeClass('profile-nav__list--hide');
          $($(this).data('dropdown')).addClass('profile-nav__list--hide');
        }
      });
    }

    return {
      toggleMenu: showAndHide,
      hideIt: hideOnOutside,
      openHamburgerMenu: hamburgerMenu
    };
  })();

  // $('.user-nav__dropdown').click(function (e){
  //   e.stopPropagation();
  // });

  dropdownMenu.toggleMenu();
  dropdownMenu.hideIt();
});
